<template>
  <EImagePlayer
    ref="player"
    :frame-index="frameIndex"
    class="evercam-player__image-player"
    :frames="frames"
    :is-playing="isPlaying"
    :time-per-frame="timePerFrame"
    :with-controls="false"
    :aspect-ratio="aspectRatio"
    :height="size.height"
    :width="size.width"
    :isLive="isLive"
    :preload="preload"
    :preload-size="preloadSize"
    :preload-while-playing="preloadWhilePlaying"
    :disable-play-button="disablePlayButton"
    :is-mobile="$device.isMobile"
    :disable-play-pause-animation="disablePlayPauseAnimation"
    :play-on-click="playOnClick"
    :placeholder-image="placeholderImage || snapshotUrl"
    :selected-snapshot-quality="selectedSnapshotQuality"
    :is-annotation-active="isAnnotationActive"
    :preloading-queue-id="preloadingQueueId"
    v-bind="$attrs"
    :is-zoomable="isZoomable"
    @update-playback="updatePlayback($event)"
    @loading="updateLoading($event)"
    @hook:beforeDestroy="updateLoading(false)"
    @update-frame-index="updateFrameIndex($event)"
    @preloaded-frames-change="$emit('update-preloaded-frames', $event)"
    @change="$emit('on-frame-props-change', $event)"
    @error="$emit('error', $event)"
    @timestamp-change="$emit('timestamp-change', $event)"
    @on-image-load="$emit('on-image-load', $event)"
    @cancel-previous-preloading="$emit('cancel-previous-preloading')"
    v-on="$listeners"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <slot></slot>
  </EImagePlayer>
</template>

<script lang="ts">
import Vue from "vue"

import { CameraStatus } from "@evercam/shared/types/camera"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

import type { Camera } from "@evercam/shared/types/camera"
import type {
  Snapshot,
  SnapshotRangeRequestPayload,
} from "@evercam/shared/types/recording"
import type { PropType } from "vue"
import { inactivityListener } from "@evercam/ui"
import { toQueryString } from "@evercam/shared/utils"
import { ImageQuality } from "@evercam/shared/types/imagePlayer"
import type { Timestamp, SrcSet, Frame } from "@evercam/shared/types"
export default Vue.extend({
  name: "EvercamPlayer",
  mixins: [inactivityListener],
  props: {
    size: {
      type: Object,
      default: () => ({}),
    },
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    selectedTimestamp: {
      type: [String, Date, Number],
      default: "",
    },
    start: {
      type: [String, Date, Number],
      default: undefined,
    },
    end: {
      type: [Date, String, Number],
      default: undefined,
    },
    authToken: {
      type: String,
      default: "",
    },
    snapshotRange: {
      type: Array,
      default: null,
    },
    customRefreshRate: {
      type: [Number],
      default: undefined,
    },
    customSchedule: {
      type: [Array],
      default: undefined,
    },
    schedule: {
      type: [Boolean, String],
      default: false,
    },
    socket: {
      type: Object as PropType<Record<any, any>>,
      default: null,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    fetchInitialSnapshots: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    selectedSnapshotQuality: {
      type: [String, Number],
      default: "auto",
    },
    timePerFrame: {
      type: Number,
      default: 250,
    },
    preload: {
      type: Boolean,
      default: true,
    },
    preloadingQueueId: {
      type: Number,
      default: 20,
    },
    preloadSize: {
      type: Number,
      default: 20,
    },
    preloadWhilePlaying: {
      type: Boolean,
      default: true,
    },
    disablePlayButton: {
      type: Boolean,
      default: false,
    },
    playOnClick: {
      type: Boolean,
      default: true,
    },
    placeholderImage: {
      type: String,
      default: undefined,
    },
    disablePlayPauseAnimation: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [Number, Boolean],
      default: false,
    },
    withOverlay: {
      type: Boolean,
      default: false,
    },
    snapshotsFetcherType: {
      type: String as PropType<"recordings" | "timelapse">,
      default: "recordings",
    },
    isZoomable: {
      type: Boolean,
      default: true,
    },
    isAnnotationActive: {
      type: Boolean,
      default: false,
    },
    maxLiveViewSnapshots: {
      type: Number,
      default: 20,
    },
    frameIndex: {
      type: Number,
      default: 0,
    },
    frames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      snapshots: [] as Snapshot[],
      defaultCount: 120,
      lastRenderedImageTimestamp: 0,
      channel: null as Record<string, any> | null,
      recordingsChannel: null as Record<string, any> | null,
      isInitialized: false,
    }
  },
  computed: {
    cameraTimezone(): string {
      return this.camera?.timezone || this.timezone
    },
    cameraExid(): string {
      return this.camera?.exid || this.camera?.id
    },
    authenticationParams(): Record<string, any> {
      let params = {}
      if (this.authToken && this.fetchInitialSnapshots) {
        params = {
          authorization: this.authToken,
        }
      }

      return params
    },
    requestParams(): Record<string, any> {
      let params: SnapshotRangeRequestPayload = {
        from: this.start as string,
        to: this.end as string,
        schedule: !!this.schedule,
      }

      if (this.count) {
        params.count = this.defaultCount
      }

      if (this.authToken) {
        params = {
          ...params,
          ...this.authenticationParams,
        }
      }

      return params
    },
    isCameraWaiting(): boolean {
      return this.camera?.status === CameraStatus.Waiting
    },
    isValidDateInterval(): boolean {
      return (
        this.$moment(this.start).isValid() && this.$moment(this.end).isValid()
      )
    },
    isStartofSelectedHour(): boolean {
      const lowerBound = this.snapshots[0]?.createdAt

      return (
        this.$moment(this.selectedTimestamp).format("YYYY-MM-DDTHHZ") ===
          this.$moment(lowerBound).format("YYYY-MM-DDTHHZ") &&
        this.$moment(this.selectedTimestamp).format("mm:ss") === "00:00"
      )
    },
    snapshotUrl(): string {
      if (this.isCameraWaiting) {
        return require("~/assets/img/waiting_for_activation.jpg")
      }

      if (this.camera.largeThumbnailUrl) {
        return this.camera.largeThumbnailUrl
      }

      return this.authToken
        ? `${this.camera?.thumbnailUrl}?authorization=${this.authToken}`
        : this.camera?.thumbnailUrl || this.placeholderImage
    },
    improperWebSocketConnection(): boolean {
      return (
        !this.camera ||
        !this.socket ||
        !this.socket.endPoint.includes(this.camera?.streamingServer)
      )
    },
    shouldInitTimestamp() {
      return this.isStartofSelectedHour && this.snapshots?.[0]
    },
  },
  watch: {
    requestParams: {
      immediate: true,
      deep: true,
      async handler(value, oldValue) {
        if (value?.from === oldValue?.from) {
          return
        }
        this.updateFrames([])
        this.updateFrameIndex(0)
        this.updatePlayback(false)

        await this.initSnapshots()

        this.initFrameIndex()
        if (!this.isInitialized) {
          this.isInitialized = true
        }
      },
    },
    shouldInitTimestamp(value) {
      if (value) {
        this.updateFrameIndex(0)
      }
    },
    camera: {
      async handler(newV, oldV) {
        if (newV.id === oldV.id) {
          return
        }
        this.updateLoading(true)
        this.leaveChannel()
        this.handleJpegStreamPlayback()
      },
    },
    isLive: {
      immediate: true,
      async handler(value: boolean) {
        if (value) {
          this.handleJpegStreamPlayback()
        } else {
          this.leaveChannel()
        }
      },
    },
    isLoading(value) {
      if (
        this.fetchInitialSnapshots &&
        (!this.snapshots?.length || !this.isValidDateInterval) &&
        !value
      ) {
        const frames = [
          {
            label: "0",
            src: this.isCameraWaiting ? this.snapshotUrl : "/unavailable.jpg",
            timestamp: "",
          },
        ]
        this.updateFrames(frames)
      }
    },
  },
  beforeDestroy() {
    this.leaveChannel()
  },
  methods: {
    updatePlayback(value: boolean) {
      this.$emit("update-playback", value)
    },
    async fetchSnapshots(): Promise<Snapshot[] | undefined> {
      if (this.snapshotRange) {
        return this.snapshotRange as Snapshot[]
      }
      let snapshots: Snapshot[] = []
      const isFromParamValid = this.$moment(this.requestParams.from).isValid(),
        isToParamValid = this.$moment(this.requestParams.to).isValid()
      if (!isFromParamValid || !isToParamValid) {
        const invalidParam = !isFromParamValid ? "from" : "to"
        this.$notifications.error({
          text: this.$t("content.invalid_field", {
            target: `${invalidParam} date param`,
          }),
          error: new Error("Invalid snapshots interval dates"),
        })

        return []
      }

      try {
        if (!this.cameraExid) {
          return
        }
        let provider: any
        provider = (EvercamApi as Record<string, any>)[
          this.snapshotsFetcherType
        ].getSnapshotRange
        const response = await provider(this.cameraExid, this.requestParams)
        snapshots = response.snapshots as Snapshot[]
      } catch (error) {
        this.updateFetchingSnapshots(false)
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "snapshots",
          }),
          error,
        })
      }

      return snapshots
    },
    reduceArrayToDesiredLength(array: any[] = [], desiredLength: number) {
      let desiredInterval = Math.round(array.length / desiredLength)
      desiredInterval = desiredInterval < 1 ? 1 : desiredInterval

      return array
        .filter((el, index) => index % desiredInterval === 0)
        .slice(0, desiredLength)
    },
    async initSnapshots() {
      if (!this.fetchInitialSnapshots || !this.cameraExid) {
        return
      }
      if (!this.withOverlay) {
        this.updateFetchingSnapshots(true)
      }
      this.snapshots = []
      const snapshots = await this.fetchSnapshots()
      if (
        this.count &&
        this.count !== this.defaultCount &&
        typeof this.count === "number"
      ) {
        this.snapshots = this.reduceArrayToDesiredLength(
          snapshots,
          this.count + 1
        )
      } else {
        this.snapshots = snapshots as Snapshot[]
      }
      this.$emit("snapshots-fetched", this.snapshots)
      const frames = this.snapshots.map(this.snapshotToFrame) as Frame[]
      this.updateFrames(frames)
    },
    getSnapshotDefaultSrc(createdAt: string | number, queryParams = {}) {
      return `${this.$config.public.apiURL}/cameras/${
        this.cameraExid
      }/recordings/snapshots/${createdAt}?${toQueryString(queryParams)}`
    },
    snapshotToFrame(snapshot: Snapshot): Frame {
      const createdAt = (snapshot?.createdAt || snapshot) as string
      const params = {
        view: true,
        ...this.authenticationParams,
      }

      return {
        label: this.$moment
          .tz(createdAt, this.cameraTimezone)
          .format(
            this.$vuetify.breakpoint.mdAndUp
              ? "DD/MM/YYYY HH:mm:ss"
              : "HH:mm:ss"
          ),
        src: this.getSnapshotDefaultSrc(createdAt, params),
        srcSet: this.getSnapshotSrcSet(createdAt, params),
        timestamp: createdAt as Timestamp,
      }
    },
    getResizedSnapshotUrl(
      quality: ImageQuality,
      createdAt: string | number,
      queryParams = {}
    ) {
      const snapshotUrl = this.getSnapshotDefaultSrc(createdAt, queryParams)

      return this.$imgproxy.getResizedImageUrl(snapshotUrl, quality)
    },
    getSnapshotSrcSet(createdAt: string | number, queryParams = {}): SrcSet {
      return Object.values(ImageQuality).reduce(
        (acc, q) => ({
          ...acc,
          [q]:
            q === ImageQuality.Auto
              ? this.getSnapshotDefaultSrc(createdAt, queryParams)
              : this.getResizedSnapshotUrl(q, createdAt, queryParams),
        }),
        {}
      ) as SrcSet
    },
    updateFrames(value) {
      this.$emit("update-frames", value)
    },
    handleJpegStreamPlayback() {
      if (!this.isLive) {
        this.updateFrameIndex(0)
        this.updatePlayback(false)

        return
      }

      if (this.improperWebSocketConnection) {
        this.$emit("init-live-socket")
      }

      this.$setTimeout(() => {
        this.playJpegStream()
      }, 2000)
    },
    playJpegStream() {
      if (
        (this.camera?.status !== CameraStatus.Online &&
          this.camera?.status !== CameraStatus.Offline) ||
        (!this.isLive && this.fetchInitialSnapshots)
      ) {
        return
      }
      this.updatePlayback(true)
      this.socket?.onError((e: any) => {
        console.error(e)
      })
      this.channel = this.socket?.channel(`cameras:${this.camera?.id}`, {})
      this.channel?.join()
      this.socket
        ?.channel(`cameras:${this.camera?.id}`, {})
        ?.on("snapshot-taken", (data) => {
          const src = `data:image/jpeg;base64,${data.image}`
          if (
            this.frames.length >= this.maxLiveViewSnapshots &&
            !this.fetchInitialSnapshots
          ) {
            const frames = this.frames
            frames.shift()
            this.updateFrames(frames)
          }
          const frames = this.frames
          frames.push({
            timestamp: this.$moment(data.timestamp * 1000).format(),
            src,
            label: this.$moment
              .tz(data.timestamp, this.cameraTimezone)
              .format("DD/MM/YYYY HH:mm:ss"),
          })
          this.updateFrames(frames)
          if (data.timestamp > this.lastRenderedImageTimestamp) {
            this.lastRenderedImageTimestamp = data.timestamp

            if (!this.customRefreshRate) {
              return
            }

            this.channel?.leave()
            let isWithinSchedule = true
            let waitTime = this.customRefreshRate
            let [minHour, maxHour] = (this.customSchedule || []) as number[]
            const currentHour = Number.parseInt(
              this.$moment.tz(new Date(), this.cameraTimezone).format("H")
            )

            if (minHour && maxHour) {
              isWithinSchedule =
                currentHour >= minHour && currentHour <= maxHour
            }

            if (!isWithinSchedule) {
              if (minHour < currentHour) {
                minHour += 24
              }
              waitTime = (minHour - currentHour) * 60 * 1000
            }
            this.$setTimeout(() => {
              this.playJpegStream()
            }, waitTime)
          }
        })
      this.subscribeToRecordingsChannel()
    },
    subscribeToRecordingsChannel() {
      this.recordingsChannel = this.socket?.channel(
        `recordings:${this.cameraExid}`,
        {}
      )
      this.recordingsChannel?.join()
      this.recordingsChannel?.on(
        "snapshot-saved",
        ({ timestamp }: { timestamp: number }) => {
          this.$emit(
            "timestamp-change",
            new Date(timestamp * 1000).toISOString()
          )
        }
      )
    },
    leaveChannel() {
      if (!this.channel) {
        return
      }
      this.socket.channels.forEach((channel) => {
        if (this.channel?.topic === channel.topic) {
          channel.leave()
        }
      })
      this.channel.leave()
      this.channel = null
      if (this.recordingsChannel) {
        this.recordingsChannel.leave()
      }
    },
    updateFrameIndex(value = 0) {
      this.$emit("update-frame-index", value)
    },
    updateLoading(value: boolean) {
      this.$emit("loading", value)
    },
    updateFetchingSnapshots(value: boolean) {
      this.$emit("fetching-snapshots", value)
    },
    initFrameIndex() {
      if (
        this.isStartofSelectedHour ||
        !this.selectedTimestamp ||
        !this.isValidDateInterval
      ) {
        this.updateFrameIndex(0)

        return
      }
      let targetIndex
      let closestDiff = Infinity
      const targetTimestamp = new Date(this.selectedTimestamp).getTime()
      this.snapshots.forEach((snapshot, index) => {
        const timestamp = snapshot.createdAt
        let diff = Math.abs(new Date(timestamp).getTime() - targetTimestamp)

        if (diff >= 0 && diff < closestDiff) {
          closestDiff = diff
          targetIndex = index
        }
      })

      this.updateFrameIndex(targetIndex)
    },
  },
})
</script>
