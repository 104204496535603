<template>
  <ESpinner :size="size" :dark="isDarkMode" :color="!isDarkMode" />
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Size } from "@evercam/ui"

export default Vue.extend({
  name: "EvercamLoadingAnimation",
  props: {
    size: {
      type: String as PropType<Size>,
      default: "SevenXl",
    },
    verifyThemeMode: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark || !this.verifyThemeMode
    },
  },
})
</script>
