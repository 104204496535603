<template>
  <v-dialog
    v-model="confirmDialogStore.dialog"
    :max-width="confirmDialogStore.options.width"
    :style="{ zIndex: confirmDialogStore.options.zIndex }"
    @keydown.esc="confirmDialogStore.cancel"
  >
    <v-card>
      <v-toolbar :class="{ background: !isAdmin }" dense flat height="60">
        <v-toolbar-title
          :class="{ 'black--text': isAdmin, 'on_background--text': !isAdmin }"
          class="pa-0"
          >{{ confirmDialogStore.title }}</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text
        v-show="!!confirmDialogStore.message"
        :class="{ background: !isAdmin }"
        class="pt-0 pa-4"
        v-html="confirmDialogStore.message"
      />
      <v-card-actions :class="{ background: !isAdmin }" class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          small
          color="grey"
          text
          @click.native="confirmDialogStore.cancel"
        >
          {{ confirmDialogStore.options.cancelText }}
        </v-btn>
        <v-btn
          small
          color="primary darken-1"
          text
          @click.native="confirmDialogStore.agree"
        >
          {{ confirmDialogStore.options.confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapStores } from "pinia"
import { useConfirmDialogStore } from "@evercam/shared/stores/confirmDialog"

export default {
  name: "ConfirmDialog",
  computed: {
    ...mapStores(useConfirmDialogStore),
    isAdmin() {
      return this.$config.public.appName === "admin"
    },
  },
}
</script>
