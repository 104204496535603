var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gmap-marker',{attrs:{"position":_vm.camera.location,"icon":_vm.cameraIcon,"draggable":_vm.isEditable},on:{"click":function($event){return _vm.$emit('click')},"mouseover":function($event){return _vm.$emit('mouseover')},"mouseout":function($event){return _vm.$emit('mouseout')},"drag":function($event){_vm.CameraDragged($event) & _vm.$emit('mouseout')},"dragend":function($event){_vm.CameraDragged($event) & _vm.$emit('input', _vm.camera)}}}),_vm._v(" "),(_vm.camera.isOnline && _vm.showFov)?_c('gmap-polygon',{attrs:{"path":_vm.FOVPoints,"draggable":_vm.isEditable,"options":{
      strokeColor: _vm.cameraColor,
      strokeOpacity: 0.8,
      strokeWeight: 4,
      fillColor: _vm.cameraColor,
      fillOpacity: '0.35',
    }},on:{"click":function($event){return _vm.$emit('click')},"mouseover":function($event){return _vm.$emit('mouseover')},"mouseout":function($event){return _vm.$emit('mouseout')},"mousemove":function($event){return _vm.$emit('mousemove', $event)},"drag":function($event){_vm.CameraDragged($event) & _vm.$emit('mouseout')},"dragend":function($event){_vm.CameraDragged($event) & _vm.$emit('input', _vm.camera)}}}):_vm._e(),_vm._v(" "),(_vm.isEditable && _vm.camera.isOnline && _vm.showFov)?_c('div',[_c('gmap-marker',{attrs:{"position":_vm.FOVMidPoint,"icon":_vm.FovCenterIcon,"flat":true,"draggable":true,"raiseOnDrag":false},on:{"drag":function($event){_vm.FOVCenterDragged($event) & _vm.$emit('mouseout')},"dragend":function($event){_vm.FOVCenterDragged($event) & _vm.$emit('input', _vm.camera)}}}),_vm._v(" "),_c('gmap-marker',{attrs:{"position":_vm.FOVLeftPoint,"icon":_vm.FovPolygonPoint,"flat":true,"draggable":true,"raiseOnDrag":false},on:{"drag":function($event){_vm.FOVEdgeDragged($event) & _vm.$emit('mouseout')},"dragend":function($event){_vm.FOVEdgeDragged($event) & _vm.$emit('input', _vm.camera)}}}),_vm._v(" "),_c('gmap-marker',{attrs:{"position":_vm.FOVRightPoint,"icon":_vm.FovPolygonPoint,"flat":true,"draggable":true,"raiseOnDrag":false},on:{"drag":function($event){_vm.FOVEdgeDragged($event) & _vm.$emit('mouseout')},"dragend":function($event){_vm.FOVEdgeDragged($event) & _vm.$emit('input', _vm.camera)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }