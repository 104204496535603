<template>
  <div
    class="evercam-player w-100"
    :style="{
      height: playerDimensions.height,
      width: playerDimensions.width,
    }"
  >
    <ELayout
      v-global-ref="'playerWrapper'"
      :width="playerDimensions.width"
      :height="playerDimensions.height"
      :with-overlay="withOverlay"
      :is-fullscreen="isFullscreen"
      class="evercam-player__layout w-100"
      :class="{ 'h-100': $device.isIos }"
      @overlay-click="onOverlayClick"
    >
      <template #main="{ size }">
        <v-fade-transition>
          <div
            v-if="(isLoading || isFetchingSnapshots) && !isVideo"
            class="evercam-player__spinner z-index-10 position-absolute h-100 w-100 d-flex justify-center align-center black opacity-40"
          >
            <ESpinner
              :color="false"
              size="SevenXl"
              opacity="0.6"
              :dark="true"
            />
          </div>
        </v-fade-transition>

        <EvercamVideoPlayer
          v-if="isVideo"
          :key="cameraExid"
          :size="size"
          :streaming-token="streamingToken"
          :camera="camera"
          :auth-token="authToken"
          :is-playing="isPlaying"
          :is-live="isLive"
          :timezone="cameraTimezone"
          :is-edge-video="isEdgeVideoEnabled"
          :is-web-rtc="isWebrtcEnabled"
          :is-video-stream="isVideoStreamEnabled"
          :hls-url="hlsUrl"
          :is-hls="
            (isVideoStreamEnabled || isEdgeVideoEnabled) && !isWebrtcEnabled
          "
          :start="start"
          :end="end"
          :frames="frames"
          :nvr-config="nvrConfig"
          :selected-timestamp="selectedTimestamp"
          :user-selected-timestamp="userSelectedTimestamp"
          :fetch-initial-snapshots="fetchInitialSnapshots"
          :preloaded-frames="preloadedFrames"
          v-bind="$attrs"
          @video-mounted="$emit('video-mounted', $event)"
          @timestamp-change="$emit('timestamp-change', $event)"
          @loading="isLoading = $event"
          @update-preloaded-frames="preloadedFrames = $event"
          @update-frame-index="frameIndex = $event"
          @update-playback="updatePlayback"
          @update-live="updateLive"
          @error="onVideoError"
          @update-frames="frames = $event"
          @on-frame-props-change="$emit('on-frame-props-change', $event)"
        >
          <template v-for="(_, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
          <slot></slot>
        </EvercamVideoPlayer>

        <EvercamImagePlayer
          v-else
          :key="cameraExid"
          ref="player"
          class="evercam-player__image-player"
          :camera="camera"
          :frames="frames"
          :frame-index="frameIndex"
          :is-playing="isPlaying"
          :is-live="isLive"
          :time-per-frame="timePerFrameValue"
          :size="size"
          :disable-play-button="disablePlayButton"
          :play-on-click="playOnClick"
          :selected-snapshot-quality="selectedSnapshotQuality"
          :preloading-queue-id="preloadingQueueId"
          :fetch-initial-snapshots="fetchInitialSnapshots"
          :selected-timestamp="selectedTimestamp"
          :with-overlay="withOverlay"
          :start="start"
          :end="end"
          :auth-token="authToken"
          :is-loading="isLoading || isFetchingSnapshots"
          v-bind="$attrs"
          @update-playback="updatePlayback($event)"
          @update-frame-index="updateFrameIndex"
          @update-frames="frames = $event"
          @loading="isLoading = $event"
          @hook:beforeDestroy="isLoading = false"
          @update-preloaded-frames="preloadedFrames = $event"
          @on-frame-props-change="$emit('on-frame-props-change', $event)"
          @error="onImageError"
          @timestamp-change="$emit('timestamp-change', $event)"
          @on-image-load="onImageLoad"
          @cancel-previous-preloading="cancelPreviousPreloading"
          @snapshots-fetched="onSnapshotsFetched"
          @fetching-snapshots="isFetchingSnapshots = $event"
          @init-live-socket="$emit('init-live-socket')"
          v-on="$listeners"
        >
          <template v-for="(_, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
          <slot></slot>
        </EvercamImagePlayer>
        <slot name="playerOverlay"></slot>
      </template>

      <template #top-right>
        <slot name="top-right"></slot>
      </template>

      <template #bottom-right>
        <slot
          name="bottom-right"
          v-bind="{
            toggleCurrentPlayer,
            isPlaying,
            isLoading,
            image: {
              data: image?.src,
              createdAt: selectedTimestamp,
            },
          }"
        ></slot>
      </template>

      <template #bottom-left>
        <slot
          name="bottom-left"
          v-bind="{
            toggleCurrentPlayer,
            isPlaying,
            isLoading,
            image: {
              data: image?.src,
              createdAt: selectedTimestamp,
            },
          }"
        ></slot>

        <v-fade-transition>
          <EZoomSlider
            v-if="
              !isVideo && withZoomSlider && !withOverlay && !$device.isMobile
            "
            class="evercam-player__zoom-slider e-ml-1"
            vertical
            @zoom-in="zoom(-1)"
            @zoom-out="zoom(1)"
          />
        </v-fade-transition>
      </template>

      <template #overlay="{ size }">
        <slot name="overlay" :size="size"></slot>
      </template>

      <template #top-left>
        <slot name="top-left"></slot>
      </template>

      <template #footer>
        <slot name="footer"></slot>
      </template>

      <template #overlay-footer>
        <div
          v-if="withControls"
          class="player__footer w-100"
          :class="{
            'px-5': $vuetify.breakpoint.smAndUp,
            'px-2': $vuetify.breakpoint.xsOnly,
          }"
        >
          <!-- Background -->
          <div class="player__footer__background"></div>

          <!-- ProgressBar -->
          <PlayerProgressBar
            v-if="withPlayerProgressBar"
            :frames="frames"
            :events="matchedSnapshotEvents"
            :frame-index="frameIndex"
            :preloaded-frames="preloadedFrames"
            :timestamp="selectedTimestamp"
            v-bind="$attrs"
            @update-frame-index="onUserSelectedFrameIndex"
            @seek="cancelPreviousPreloading"
            @marker-active="$emit('marker-active', $event)"
            @marker-reset="$emit('marker-reset', $event)"
            @marker-selected="$emit('marker-selected', $event)"
          >
            <template #tooltip="{ hoveredTimestamp }">
              <EdgeVideoThumbnailPreview
                v-if="
                  isEdgeVideoEnabled &&
                  showBif &&
                  !isWebrtcEnabled &&
                  !isVideoStreamEnabled
                "
                :nvr-config="nvrConfig"
                :streaming-token="streamingToken"
                :target-timestamp="hoveredTimestamp"
              />
            </template>
          </PlayerProgressBar>

          <!-- Actions -->
          <PlayerActions
            v-if="withPlayerActions"
            :frames="frames"
            :frame-index="frameIndex"
            :time-per-frame="timePerFrame"
            :is-playing="isPlaying"
            :info-text="infoText"
            :is-live="isLive"
            :has-live="hasLive && isCameraOnline"
            :is-last-frame="isLastFrame"
            :disable-play-button="
              disablePlayButton || isFetchingSnapshots || snapshots.length === 0
            "
            :initial-snapshot-quality="selectedSnapshotQuality ?? 'auto'"
            :show-snapshot-quality="showSnapshotQuality"
            :is-video="isVideo"
            :show-player-mode-toggle="showPlayerModeToggle"
            @update-playback="updatePlayback($event)"
            @snapshots-quality="onSnapshotQualityChange"
            @update-live="updateLive($event)"
            @update-time-per-frame="timePerFrameValue = $event"
            @update-fullscreen="isFullscreen = $event"
            @update-frame-index="onUserSelectedFrameIndex"
            @toggle-ios-fullscreen="$emit('toggle-ios-fullscreen')"
            @update-player-mode="onPlayerModeUpdate"
          />
        </div>
      </template>
    </ELayout>
  </div>
</template>

<script lang="ts">
import Vue from "vue"

import EvercamVideoPlayer from "@evercam/shared/components/EvercamVideoPlayer"
import EvercamImagePlayer from "@evercam/shared/components/EvercamImagePlayer"
import PlayerProgressBar from "@evercam/shared/components/imagePlayer/PlayerProgressBar"
import PlayerActions from "@evercam/shared/components/imagePlayer/PlayerActions"
import EdgeVideoThumbnailPreview from "@evercam/shared/components/imagePlayer/EdgeVideoThumbnailPreview"
import { ImageQuality } from "@evercam/shared/types/imagePlayer"
import { CameraStatus } from "@evercam/shared/types/camera"
import { matchEventsWithClosestPlayerFrameIndex } from "@evercam/shared/utils"

import type { Camera, NvrConfig } from "@evercam/shared/types/camera"
import type { Snapshot } from "@evercam/shared/types/recording"
import { AnalyticsEvent, PlayerMode } from "@evercam/shared/types"
import type { GateReportEvent } from "@evercam/shared/types/gateReport"
import type { PropType } from "vue"
import type {
  Frame,
  MatchedSnapshotEvent,
  SnapshotEvent,
} from "@evercam/shared/types/imagePlayer"
import { EZoomSlider, inactivityListener } from "@evercam/ui"
export default Vue.extend({
  name: "EvercamPlayer",
  components: {
    PlayerProgressBar,
    PlayerActions,
    EdgeVideoThumbnailPreview,
    EZoomSlider,
    EvercamVideoPlayer,
    EvercamImagePlayer,
  },
  mixins: [inactivityListener],
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    nvrConfig: {
      type: Object as PropType<NvrConfig>,
      default: () => ({}),
    },
    selectedTimestamp: {
      type: [String, Date, Number],
      default: "",
    },
    start: {
      type: [String, Date, Number],
      default: undefined,
    },
    end: {
      type: [Date, String, Number],
      default: undefined,
    },
    authToken: {
      type: String,
      default: "",
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    showPlayerModeToggle: {
      type: Boolean,
      default: false,
    },
    hasLive: {
      type: Boolean,
      default: false,
    },
    streamingToken: {
      type: String,
      default: "",
    },
    showBif: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    //💡: this props will tell us if we are on live view or not <> isLive = !fetchInitialSnapshot
    fetchInitialSnapshots: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Array as PropType<SnapshotEvent<GateReportEvent>[]>,
      default: () => [],
    },
    initialSnapshotQuality: {
      type: [String, Number],
      default: "auto",
    },
    showSnapshotQuality: {
      type: Boolean,
      default: true,
    },
    timePerFrame: {
      type: Number,
      default: 250,
    },
    withControls: {
      type: Boolean,
      default: true,
    },
    disablePlayButton: {
      type: Boolean,
      default: false,
    },
    playOnClick: {
      type: Boolean,
      default: true,
    },
    withOverlay: {
      type: Boolean,
      default: false,
    },
    isVideoDisabled: {
      type: Boolean,
      default: false,
    },
    isWebRtcDisabled: {
      type: Boolean,
      default: false,
    },
    withPlayerActions: {
      type: Boolean,
      default: true,
    },
    withPlayerProgressBar: {
      type: Boolean,
      default: true,
    },
    withZoomSlider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      snapshots: [] as Snapshot[],
      image: null as HTMLImageElement | null,
      isVideoError: false,
      isFetchingSnapshots: false,
      frames: [] as Frame[],
      frameIndex: 0,
      infoText: {
        index: "",
        label: "",
      },
      userSelectedTimestamp: 0 as string | number,
      preloadedFrames: [] as number[],
      preloadingQueueId: 0,
      selectedSnapshotQuality: this.initialSnapshotQuality,
      isLoading: true,
      timePerFrameValue: this.timePerFrame,
      isFullscreen: false,
      cacheEventsMatching: false,
      previousEventsTimestamps: [],
    }
  },
  computed: {
    playerDimensions(): { height: string; width: string } {
      if (this.isFullscreen && !this.$device.isIos) {
        return {
          height: `${window.innerHeight}px`,
          width: "100%",
        }
      } else {
        return {
          height: `${this.$attrs.height}px`,
          width: `${this.$attrs.width}px`,
        }
      }
    },
    cameraTimezone(): string {
      return this.camera?.timezone || this.timezone
    },
    cameraExid(): string {
      return this.camera?.exid
    },
    isVideo(): boolean {
      if (this.isVideoDisabled || this.isVideoError) {
        return false
      }

      if (this.isLive) {
        return (
          this.isWebrtcEnabled || this.isVideoStreamEnabled || this.isEdgeVideo
        )
      }

      if (this.isWebrtcEnabled) {
        return !this.fetchInitialSnapshots
      }

      return this.isEdgeVideo
    },
    isEdgeVideoEnabled() {
      return this.isEdgeVideo && !this.isVideoError
    },
    isWebrtcEnabled(): boolean {
      return (
        this.$permissions.camera?.has.webrtc() &&
        this.isCameraOnline &&
        !this.$device.isIos &&
        !this.isWebRtcDisabled
      )
    },
    isVideoStreamEnabled(): boolean {
      return (
        this.$permissions.camera?.has.videoStream() &&
        this.hlsUrl &&
        !this.isWebrtcEnabled &&
        !this.$device.isFirefox
      )
    },
    hlsUrl(): string {
      return this.camera?.proxyUrl?.hls
    },
    isLastFrame(): boolean {
      return this.frameIndex === this.frames.length - 1
    },
    matchedSnapshotEvents(): MatchedSnapshotEvent<GateReportEvent>[] {
      if (this.cacheEventsMatching && this.previouslyMatchedEvents) {
        return this.events.map((e) => {
          const cachedMatchedEvent =
            this.previouslyMatchedEvents.find(
              (event) =>
                (event.id && e.id && event.id === e.id) ||
                (event.tempId && e.tempId && event.tempId === e.tempId)
            ) || {}

          return {
            ...e,
            ...cachedMatchedEvent,
          }
        })
      } else {
        return matchEventsWithClosestPlayerFrameIndex(this.events, this.frames)
      }
    },
    isCameraOnline(): boolean {
      return this.camera?.status === CameraStatus.Online
    },
  },
  watch: {
    withOverlay: {
      immediate: true,
      handler(value) {
        if (value) {
          this.isFetchingSnapshots = false
          this.isLoading = false
        }
      },
    },
    isVideo: {
      async handler(value) {
        this.$emit("toggle-video-mode", value)
      },
      immediate: true,
    },
    // The following avoids recomputing the matched events whenever an event object changes
    // TODO Remove after migrating to Vue 3, and use v-memo instead
    matchedSnapshotEvents(newVal, oldVal) {
      if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
        return
      }
      this.cacheEventsMatching = true
      this.previouslyMatchedEvents = newVal.map((e) => {
        return {
          id: e.id,
          tempId: e.tempId,
          frameIndex: e.frameIndex,
          snapshotTimestamp: e.snapshotTimestamp,
        }
      })
    },
    camera() {
      if (!this.hasLive) {
        this.updateLive(false)
      }
    },
    frameIndex: {
      immediate: true,
      handler(index, previousIndex) {
        if (
          (index <= 0 && previousIndex > 0) ||
          index === this.frames.length - 1
        ) {
          this.$emit("snapshot-edge-reached")
        }

        if (index === previousIndex) {
          return
        }

        this.updateToLatestFrameOnLive()
        if (index && Math.abs(index - previousIndex) > 1 && !this.withOverlay) {
          this.isLoading = true
        }
      },
    },
    frames: {
      immediate: true,
      handler() {
        this.updateToLatestFrameOnLive()
        this.cacheEventsMatching = false
      },
    },
    events(newVal) {
      const eventsTimestamps = newVal.map((e) => e.eventTime)
      this.cacheEventsMatching =
        JSON.stringify(this.previousEventsTimestamps) ===
        JSON.stringify(eventsTimestamps)
      this.previousEventsTimestamps = eventsTimestamps
    },
    isLive: {
      immediate: true,
      async handler(value: boolean) {
        this.updateToLatestFrameOnLive()
        if (value) {
          this.isLoading = true
          this.updatePlayback(true)
        } else {
          this.updateLive(false)
        }
      },
    },
    selectedTimestamp(t) {
      this.resetUserSelectedTimestampOnHourChange(t)
    },
    isLoading: {
      immediate: true,
      handler(val) {
        this.$emit("loading", val)
      },
    },
  },
  mounted() {
    this.$addEventListener("fullscreenchange", this.setFullscreen)
    this.$addEventListener("webkitfullscreenchange", this.setFullscreen)
    this.$addEventListener("mozfullscreenchange", this.setFullscreen)
    this.$addEventListener("MSFullscreenChange", this.setFullscreen)
  },
  methods: {
    updateFrameIndex(index: number) {
      this.frameIndex = index
    },
    setFullscreen() {
      this.isFullscreen =
        document.fullscreenElement ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement
    },
    updatePlayback(value: boolean) {
      this.$emit("update-playback", value)
    },
    updateLive(value: boolean) {
      this.$emit("update-live", value)
    },
    zoom(direction: number) {
      const player = this.$refs?.player?.$refs?.player as any
      const zoomableImage = player.$refs?.zoomableImage
      zoomableImage.zoom(direction)
      this.$root.$emit("analytics-event", {
        eventId:
          direction > 0
            ? AnalyticsEvent.PlayerZoomOut
            : AnalyticsEvent.PlayerZoomIn,
        params: { zoomLevel: zoomableImage.zoomLevel },
      })
    },
    onOverlayClick() {
      if (this.playOnClick) {
        this.updatePlayback(!this.isPlaying)
      }
    },
    onSnapshotsFetched(snapshots: Snapshot[]) {
      this.snapshots = snapshots
      this.$emit("snapshots-fetched", snapshots)
      this.isFetchingSnapshots = false
    },
    updateToLatestFrameOnLive() {
      if (!this.isLive) {
        return
      }
      this.frameIndex = this.frames.length > 0 ? this.frames.length - 1 : 0
    },
    toggleCurrentPlayer() {
      if (this.isLive || !this.fetchInitialSnapshots) {
        this.toggleLive()
      } else {
        this.updatePlayback(!this.isPlaying)
      }
    },
    toggleLive() {
      this.updateLive(!this.isPlaying)
      this.updatePlayback(!this.isPlaying)
    },
    updateInfoText() {
      const frameOrder = `${Math.min(
        this.frameIndex + 1,
        this.frames.length
      )} / ${this.frames.length}`

      this.infoText = {
        index: frameOrder,
        label: this.frames[Math.max(0, this.frameIndex)]?.label || "",
      }
    },
    onUserSelectedFrameIndex(frameIndex: number) {
      this.updateLive(false)
      this.userSelectedTimestamp = this.frames[frameIndex].timestamp
      this.frameIndex = frameIndex
      this.$emit("timestamp-change", this.frames[frameIndex].timestamp)
    },
    onImageLoad(e: any) {
      this.image = e as HTMLImageElement
      this.updateInfoText()
      this.isLoading = false
    },
    onImageError() {
      this.isLoading = false
    },
    onSnapshotQualityChange(q: ImageQuality) {
      this.selectedSnapshotQuality = q
    },
    cancelPreviousPreloading() {
      if (!this.isVideo) {
        this.preloadingQueueId += 1
      }
    },
    onVideoError(e: Error) {
      this.isVideoError = true
      this.frameIndex = 0
      this.$emit("error", e)
      this.$emit("toggle-video-mode", false)
    },
    resetUserSelectedTimestampOnHourChange(t: string | Date) {
      const selectedTime = this.$moment(t)
      const userSelectedTime = this.$moment(this.userSelectedTimestamp)
      if (
        Math.abs(selectedTime.diff(userSelectedTime, "hours")) > 1 ||
        selectedTime.hours() !== userSelectedTime.hours()
      ) {
        this.userSelectedTimestamp = this.selectedTimestamp
      }
    },
    onPlayerModeUpdate(value: string) {
      const isVideoMode = value === PlayerMode.Video
      if (isVideoMode) {
        this.isVideoError = false
      }
      this.$emit("toggle-video-mode", isVideoMode)
    },
  },
})
</script>

<style>
.player__footer {
  position: relative !important;
}
</style>
