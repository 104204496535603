<template>
  <EVideoPlayer
    ref="player"
    :key="videoPlayerKey"
    class="evercam-player__video-player video-player-box h-100 w-100"
    :height="size.height"
    :width="size.width"
    :sources="activeVideoModeSources"
    :video-options="videoPlayerOptions"
    :video-listeners="videoPlayerListeners"
    :controls="false"
    :is-hls="isHls"
    :is-web-rtc="isWebRtc"
    :streaming-token="streamingToken || authToken"
    :is-playing="isPlaying"
    :is-zoomable="true"
    :timezone="timezone"
    :with-overlay-on-background="true"
    :target-timestamp="videoTargetTimestamp"
    :blur-background="true"
    v-bind="$attrs"
    @error="emitVideoError"
    @hook:mounted="onVideoPlayerMounted"
    @on-frame-props-change="$emit('on-frame-props-change', $event)"
  >
    <template v-for="(_, name) in $scopedSlots" #[name]="data">
      <slot :name="name" v-bind="data"></slot>
    </template>
    <slot></slot>
  </EVideoPlayer>
</template>

<script lang="ts">
import Vue from "vue"
import type { Camera, NvrConfig } from "@evercam/shared/types"
import { WebRtcApi } from "@evercam/shared/api/webRtcApi"
import type { PropType } from "vue"

enum VideoMode {
  VideoStream = "video_stream",
  WebRtc = "web_rtc",
  EdgeVideo = "edge_video",
}

export default Vue.extend({
  name: "EvercamVideoPlayer",
  props: {
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    size: {
      type: Object,
      default: () => ({}),
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    fetchInitialSnapshots: {
      type: Boolean,
      default: false,
    },
    isEdgeVideo: {
      type: Boolean,
      default: false,
    },
    isWebRtc: {
      type: Boolean,
      default: false,
    },
    isVideoStream: {
      type: Boolean,
      default: false,
    },
    hlsUrl: {
      type: String,
      default: null,
    },
    isHls: {
      type: Boolean,
      default: false,
    },
    nvrConfig: {
      type: Object as PropType<NvrConfig>,
      default: () => ({}),
    },
    streamingToken: {
      type: String,
      default: "",
    },
    authToken: {
      type: String,
      default: "",
    },
    selectedTimestamp: {
      type: [String, Date, Number],
      default: "",
    },
    userSelectedTimestamp: {
      type: [String, Date, Number],
      default: "",
    },
    frames: {
      type: Array,
      default: () => [],
    },
    preloadedFrames: {
      type: Array,
      default: () => [],
    },
    frameIndex: {
      type: Number,
      default: 0,
    },
    start: {
      type: [String, Date, Number],
      default: undefined,
    },
    end: {
      type: [Date, String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      videoPlayerKey: 0,
      videoElement: null as HTMLVideoElement | null,
      videoCurrentTimestamp: null as string | null,
      previousPlayerTime: 0,
      isSeek: false,
      mediaStream: null as MediaStream | null,
      pc: null as RTCPeerConnection | null,
      restartTimeout: null as number | null,
      eTag: "",
      sessionUrl: "",
      queuedCandidates: [] as any[],
      restartPause: 2000,
      offerData: null as {
        iceUfrag: string
        icePwd: string
        medias: never[]
      } | null,
      playbackRetries: 0,
    }
  },
  computed: {
    baseWebRtcUrl(): string {
      return `${this.$config.public.mediaMtxWebRtcUrl}/streaming/webrtc`
    },
    webRtcUrl(): string {
      return `${this.baseWebRtcUrl}/${this.camera.id}/whep`
    },
    sourcesByVideoMode(): Record<string, Array<{ src: string; type: string }>> {
      return {
        [VideoMode.EdgeVideo]: [
          {
            src: this.videoEdgeStreamUrl,
            type: "application/x-mpegURL",
          },
        ],
        [VideoMode.WebRtc]: [
          {
            src: this.webRtcUrl,
            type: "application/sdp",
          },
        ],
        [VideoMode.VideoStream]: [
          {
            src: this.hlsUrl,
            type: "application/x-mpegURL",
          },
        ],
      }
    },
    activeVideoMode() {
      if (this.isWebRtc) {
        return VideoMode.WebRtc
      } else if (this.isVideoStream) {
        return VideoMode.VideoStream
      } else {
        return VideoMode.EdgeVideo
      }
    },
    activeVideoModeSources(): Array<{ src: string; type: string }> {
      return this.sourcesByVideoMode[this.activeVideoMode]
    },
    videoEdgeStreamUrl(): string {
      const nvrConfig = this.nvrConfig as NvrConfig
      const timestamp = this.videoTargetTimestamp
        ? this.$moment(this.videoTargetTimestamp).format("YYYY-MM-DDTHH:mm:ssZ")
        : ""

      return `${nvrConfig.streamingUrl}?pos=${encodeURIComponent(
        this.fetchInitialSnapshots ? timestamp : ""
      )}&retries=${this.playbackRetries}`
    },
    videoTargetTimestamp(): string | number {
      if (this.isLive) {
        return new Date().toISOString()
      } else {
        return this.userSelectedTimestamp || this.selectedTimestamp
      }
    },
    posterUrl(): string | undefined {
      if (this.isWebRtc || this.isVideoStream) {
        return
      }

      return this.getVideoSnapshotUrl(this.videoTargetTimestamp as string)
    },
    videoPlayerOptions(): Record<string, any> {
      return {
        muted: true,
        playsinline: true,
        autoplay: this.isPlaying,
        controls: false,
        poster: this.posterUrl,
      }
    },
    videoPlayerListeners(): Record<string, any> {
      return {
        timeupdate: this.onVideoTimeUpdate,
        progress: this.onVideoProgress,
        error: this.emitVideoError,
        play: this.onVideoPlay,
        pause: this.onVideoPause,
        canplay: this.onVideoCanPlay,
        loadeddata: this.onVideoLoadedData,
        canplaythrough: this.onVideoCanPlay,
      }
    },
  },
  watch: {
    activeVideoMode: {
      handler(v) {
        console.log("here *** mode: ", v)
      },
      immediate: true,
    },
    videoTargetTimestamp(timestamp) {
      this.onVideoTargetTimestampChange(timestamp)
    },
    selectedTimestamp(timestamp) {
      this.videoCurrentTimestamp = timestamp
      this.updateFrameIndex()
    },
    frames: {
      handler() {
        this.updateFrameIndex()
      },
      immediate: true,
    },
    start() {
      this.updateVideoFrames()
      this.updateFrameIndex()
    },
    end() {
      this.updateFrameIndex()
    },
    isPlaying: {
      handler(value) {
        if (this.isWebRtc && value) {
          this.refreshVideoPlayer()
          this.scheduleRestart()
        }
      },
      immediate: true,
    },
    camera: {
      async handler(value, oldValue) {
        if (value.id === oldValue.id) {
          return
        }
        this.refreshVideoPlayer()
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.closeConnection()
  },
  mounted() {
    if (this.isWebRtc && !this.isPlaying) {
      this.$emit("update-live", true)
    }
    this.updateVideoFrames()
  },
  methods: {
    async startWebRtc() {
      console.log("requesting ICE servers")
      try {
        const response = await WebRtcApi.webRtc.requestIceServers({
          url: this.webRtcUrl,
          token: this.authToken,
        })
        this.onIceServers(response)
      } catch (error) {
        console.error("error: ", error)
        this.scheduleRestart()
      }
    },
    onIceServers(res: any) {
      this.pc = new RTCPeerConnection({
        // @ts-ignore
        iceServers: this.extractIceServers(res.headers["Link"]),
      })
      const direction = "sendrecv"
      this.pc.addTransceiver("video", { direction })
      this.pc.addTransceiver("audio", { direction })

      this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt)
      this.pc.oniceconnectionstatechange = () => this.onConnectionState()

      this.pc.ontrack = (evt) => {
        console.log("new track:", evt.track.kind)
        this.mediaStream = evt.streams[0]
        if (!this.videoElement) {
          return
        }
        this.videoElement.srcObject = this.mediaStream
      }

      this.pc.createOffer().then((offer) => this.onLocalOffer(offer))
    },
    unquoteCredential(v: any) {
      return JSON.parse(`"${v}"`)
    },
    extractIceServers(links: string) {
      if (!links) {
        return
      }

      return links.split(", ").map((link) => {
        const match = link.match(
          /^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i
        )

        return this.createIceServerConfig(match)
      })
    },
    createIceServerConfig(match) {
      const urls = [match?.[1]]
      const username = match?.[3]
      const credential = match?.[4]

      let iceServer: Record<string, any> = {
        urls,
      }

      if (username && credential) {
        iceServer = {
          ...iceServer,
          username,
          credential,
          credentialType: "password",
        }
      }

      return iceServer
    },
    async onLocalOffer(offer: any) {
      this.offerData = this.parseOffer(offer.sdp)
      this.pc?.setLocalDescription(offer)

      console.log("sending offer")
      try {
        const response = await WebRtcApi.webRtc.sendLocalOffer({
          url: this.webRtcUrl,
          token: this.authToken,
          data: offer.sdp,
        })
        if (response.status !== 201) {
          throw new Error("bad status code")
        }
        this.eTag = response.headers["E-Tag"] as string
        this.sessionUrl = this.baseWebRtcUrl + response.headers["location"]

        const sdp = await response.data
        this.onRemoteAnswer(
          new RTCSessionDescription({
            type: "answer",
            sdp,
          })
        )
      } catch (error) {
        console.error("error: ", error)
        if (this.playbackRetries < 3) {
          this.scheduleRestart()
        } else {
          this.closeConnection()
        }
        this.emitVideoError(error)
      }
    },
    onConnectionState() {
      if (this.restartTimeout || !this.isPlaying) {
        return
      }

      console.log("peer connection state:", this.pc?.iceConnectionState)

      switch (this.pc?.iceConnectionState) {
        case "disconnected":
          this.updateLoading(true)
          this.scheduleRestart()
          break
        case "checking":
          this.updateLoading(true)
          break
      }
    },
    onRemoteAnswer(answer: any) {
      if (this.restartTimeout) {
        return
      }

      this.pc?.setRemoteDescription(new RTCSessionDescription(answer))

      if (this.queuedCandidates.length !== 0) {
        this.sendLocalCandidates(this.queuedCandidates)
        this.queuedCandidates = []
      }
    },
    onLocalCandidate(evt: any) {
      if (this.restartTimeout) {
        return
      }

      if (!evt.candidate) {
        return
      }

      if (!this.eTag) {
        this.queuedCandidates.push(evt.candidate)
      } else {
        this.sendLocalCandidates([evt.candidate])
      }
    },
    async sendLocalCandidates(candidates: any[]) {
      try {
        const response = await WebRtcApi.webRtc.sendLocalCandidates({
          url: this.sessionUrl,
          token: this.authToken,
          data: this.generateSdpFragment(this.offerData, candidates),
          tag: this.eTag,
        })

        if (response.status !== 204) {
          throw new Error("bad status code")
        }
      } catch (error) {
        console.error("error: ", error)
        this.scheduleRestart()
      }
    },
    scheduleRestart() {
      if (this.restartTimeout !== null) {
        return
      }
      this.updateLoading(true)

      this.closeConnection()

      this.restartTimeout = window.setTimeout(() => {
        this.restartTimeout = null
        this.startWebRtc()
      }, this.restartPause)
      this.eTag = ""
      this.queuedCandidates = []
    },
    closeConnection() {
      this.pc?.close()
      this.pc = null
    },
    parseOffer(offer: any) {
      const ret = {
        iceUfrag: "",
        icePwd: "",
        medias: [] as any[],
      }

      for (const line of offer.split("\r\n")) {
        if (line.startsWith("m=")) {
          ret.medias.push(line.slice("m=".length))
        } else if (ret.iceUfrag === "" && line.startsWith("a=ice-ufrag:")) {
          ret.iceUfrag = line.slice("a=ice-ufrag:".length)
        } else if (ret.icePwd === "" && line.startsWith("a=ice-pwd:")) {
          ret.icePwd = line.slice("a=ice-pwd:".length)
        }
      }

      return ret
    },
    generateSdpFragment(offerData, candidates) {
      const candidatesByMedia = {}
      for (const candidate of candidates) {
        const mid = candidate.sdpMLineIndex
        if (candidatesByMedia[mid] === undefined) {
          candidatesByMedia[mid] = []
        }
        candidatesByMedia[mid].push(candidate)
      }

      let frag =
        "a=ice-ufrag:" +
        offerData.iceUfrag +
        "\r\n" +
        "a=ice-pwd:" +
        offerData.icePwd +
        "\r\n"

      let mid = 0

      for (const media of offerData.medias) {
        if (candidatesByMedia[mid] !== undefined) {
          frag += "m=" + media + "\r\n" + "a=mid:" + mid + "\r\n"

          for (const candidate of candidatesByMedia[mid]) {
            frag += "a=" + candidate.candidate + "\r\n"
          }
        }
        mid++
      }

      return frag
    },
    updateVideoFrames() {
      const startTime = this.$moment(this.start)
      const endTime = this.$moment(this.end).isAfter(this.$moment())
        ? this.$moment()
        : this.$moment(this.end)

      const duration = endTime.diff(startTime, "seconds")

      const frames = Array.from({ length: duration + 1 }, (_, i) => {
        const currentTimestamp = this.$moment(startTime).add(i, "seconds")

        return {
          timestamp: currentTimestamp.format(),
          label: currentTimestamp
            .tz(this.timezone)
            .format(
              this.$vuetify.breakpoint.mdAndUp
                ? "DD/MM/YYYY HH:mm:ss"
                : "HH:mm:ss"
            ),
        }
      })

      this.$emit("update-frames", frames)
    },
    onVideoPlayerMounted() {
      this.videoElement = this.$refs?.player?.$refs?.player
      this.$emit("video-mounted", this.videoElement)
    },
    onVideoLoadedData() {
      if (this.isPlaying) {
        this.videoElement?.play()
      }
    },
    onVideoCanPlay() {
      this.updateLoading(false)
      if (this.isPlaying) {
        this.videoElement?.play()
      }
    },
    updatePlayback(value: boolean) {
      this.$emit("update-playback", value)
    },
    updateLoading(value: boolean) {
      this.$emit("loading", value)
    },
    onVideoPlay() {
      this.updatePlayback(true)
      this.previousPlayerTime = this.videoElement?.currentTime ?? 0
    },
    onVideoPause() {
      this.updatePlayback(false)
    },
    onVideoTimeUpdate() {
      if (!this.videoElement) {
        return
      }
      const currentPlayerTime = this.videoElement?.currentTime

      if (
        (this.previousPlayerTime === 0 && currentPlayerTime > 1) ||
        this.isSeek
      ) {
        this.previousPlayerTime = currentPlayerTime
        this.isSeek = false

        return
      }
      this.videoCurrentTimestamp = this.$moment(this.videoTargetTimestamp)
        .tz(this.timezone)
        .add(Math.floor(currentPlayerTime), "seconds")
        .format("YYYY-MM-DDTHH:mm:ssZ")

      this.previousPlayerTime = currentPlayerTime
      this.$emit("timestamp-change", this.videoCurrentTimestamp)
    },
    onVideoTargetTimestampChange(timestamp: string) {
      if (!this.videoElement || !timestamp) {
        return
      }

      this.videoElement.currentTime = 0
      this.videoCurrentTimestamp = timestamp
      this.isSeek = true
      this.refreshVideoPlayer()
    },
    refreshVideoPlayer() {
      if (!this.fetchInitialSnapshots && !this.isLive) {
        return
      }
      this.videoPlayerKey++
    },
    onVideoProgress() {
      if (!this.videoElement?.buffered.length) {
        return
      }
      try {
        const preloadedSeconds = this.videoElement.buffered.end(
          this.videoElement.buffered.length - 1
        )
        this.onVideoPreloadedIntervalChange({
          start: this.$moment(this.videoTargetTimestamp).format(),
          end: this.$moment(this.videoTargetTimestamp)
            .add(preloadedSeconds, "seconds")
            .format(),
        })
      } catch (e) {
        console.error(e)
      }
    },
    onVideoPreloadedIntervalChange({
      start,
      end,
    }: {
      start: string
      end: string
    }) {
      const startTime = this.$moment(start)
      const endTime = this.$moment(end)
      const frameIndexes = []
      let currentSecond = startTime.clone()
      while (currentSecond <= endTime) {
        frameIndexes.push(
          currentSecond.minutes() * 60 + currentSecond.seconds()
        )
        currentSecond.add(1, "seconds")
      }
      const preloadedFrames = Array.from(
        new Set([...this.preloadedFrames, ...frameIndexes])
      )
      this.$emit("update-preloaded-frames", preloadedFrames)
    },
    emitVideoError(e: any) {
      if (this.playbackRetries >= 3) {
        this.$emit("error", e)

        return
      }

      if (e.type === "networkError" || e.type === "mediaError") {
        this.refreshVideoPlayer()
      }
      this.playbackRetries += 1
    },
    getVideoSnapshotUrl(timestamp: string): string | undefined {
      if (!this.isEdgeVideo) {
        return
      }

      const formattedTimestamp = timestamp
        ? this.$moment.utc(timestamp).format("YYYY-MM-DDTHH:mm:ss[Z]")
        : ""

      return `${
        (this.nvrConfig as NvrConfig).snapshotUrl
      }?access_token=${encodeURIComponent(
        this.streamingToken
      )}&time=${encodeURIComponent(formattedTimestamp)}`
    },
    updateFrameIndex() {
      const selectedTime = this.$moment(this.selectedTimestamp)
      const startTime = this.$moment(this.start)
      const duration = this.$moment.duration(selectedTime.diff(startTime))
      const frameIndex = Math.abs(duration.seconds() + duration.minutes() * 60)

      if (frameIndex <= this.frames.length - 1) {
        this.$emit("update-frame-index", frameIndex)
      }

      if (!this.isLive && frameIndex >= this.frames.length - 1) {
        this.updatePlayback(false)
      }
    },
  },
})
</script>
