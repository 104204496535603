<template>
  <div>
    <v-select
      v-model="selectedProjectEvents"
      :items="companyProjects"
      dense
      outlined
      solo
      clearable
      label="Filter by project"
      hide-details
      item-value="exid"
      item-text="name"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { EvercamApi } from "@evercam/shared/api/evercamApi"
export default {
  name: "CompanyProjectSelect",
  props: {
    companyId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      selectedProjectEvents: [],
      companyProjects: [],
    }
  },
  async mounted() {
    await this.fetchCompanyProjects()
  },
  methods: {
    async fetchCompanyProjects() {
      if (!this.companyId) {
        return
      }
      try {
        const response = await EvercamApi.companies.getCompanyProjects(
          this.companyId
        )
        this.companyProjects = response.items
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.fetch_resource_failed", {
            resource: "company projects",
          }),
          error,
        })
      }
    },
  },
}
</script>
